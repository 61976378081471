<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Бюджетное планирование</span>
        </div>
        <div class="section-subtitle"><i class="icon icon-grid"></i>Уточнение бюджета</div>
        <div>
            <b-progress
                variant="success"
                v-show="progress<100"
                height="10px"
                :value="progress"
                striped
                animated
            ></b-progress>
        </div>
        <b-tabs class="w-100" v-model="active_tab">
            <b-tab title="Расходы">
                <div class="actions-tab in-tab">
                    <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <div class="btn-download">
                                <div>
                                                            <span class="left">
                                                                <i class="icon icon-download"></i> Скачать
                                                            </span>
                                    <i class="icon icon-keyboard"></i>
                                </div>
                            </div>
                        </template>
                        <b-dropdown-item @click="downloadToExcelCost">Отчет по уточнению
                        </b-dropdown-item>
                        <!--                        <b-dropdown-item @click="downloadToExcel">Отчет по корректировке обл-->
                        <!--                            бюджета-->
                        <!--                        </b-dropdown-item>-->
                    </b-dropdown>
                    <b-dropdown variant="link" class="info" right
                                toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <div class="btn-download">
                                <div>
                                        <span class="left">
                                            <i class="icon icon-info"></i>
                                        </span>
                                    <i class="icon icon-keyboard"></i>
                                </div>
                            </div>
                        </template>
                        <b-dropdown-text>
                            <div class="info-button">
                                <span @click="infoCostForm(3)">Режим «Уточнение бюджета»<i
                                    class="icon icon-folder"></i></span>
                            </div>
                            <!-- <div class="info-button">
                                <span>Видеоинструкция <i class="icon icon-video"></i></span>
                            </div> -->
                            <div class="info-text">
                                <p>Режим предназначен для внесения изменений и дополнений в
                                    утвержденный/уточненный бюджет.</p>
                                <p>Режим содержит данные до уровня бюджетных программ/подпрограмм по
                                    расходной части и специфик по поступлениям.</p>
                                <p>Данный режим состоит из разделов:</p>
                                <p><span class="red bold">Расходы</span><br> - уточнение расходной
                                    части бюджета</p>
                                <p><span class="red bold">Доходы</span><br> - уточнение доходной
                                    части бюджета</p>
                            </div>
                        </b-dropdown-text>
                    </b-dropdown>

                </div>
                <template>
                    <cost-clarify-spf :year="year"
                                      :obl="obl"
                                      :reg="region"
                                      :variant="variant"
                                      :list-reg="listReg"
                                      :list-variants="listVariants"
                                      @change_filter="changeChildFilter"
                    ></cost-clarify-spf>
                </template>
            </b-tab>
            <b-tab title="Доходы" lazy>
                <div class="actions-tab in-tab">
                    <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <div class="btn-download">
                                <div>
                                                            <span class="left">
                                                                <i class="icon icon-download"></i> Скачать
                                                            </span>
                                    <i class="icon icon-keyboard"></i>
                                </div>
                            </div>
                        </template>
                        <b-dropdown-item @click="downloadToExcelIncome">Отчет по уточнению
                        </b-dropdown-item>
                        <!--                        <b-dropdown-item @click="downloadToExcel">Отчет по корректировке обл-->
                        <!--                            бюджета-->
                        <!--                        </b-dropdown-item>-->
                    </b-dropdown>
                    <b-dropdown variant="link" class="info" right
                                toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <div class="btn-download">
                                <div>
                                        <span class="left">
                                            <i class="icon icon-info"></i>
                                        </span>
                                    <i class="icon icon-keyboard"></i>
                                </div>
                            </div>
                        </template>

                        <b-dropdown-text>
                            <div class="info-button">
                                <span @click="infoCostForm(4)">Режим «Уточнение бюджета»<i
                                    class="icon icon-folder"></i></span>
                            </div>
                            <!-- <div class="info-button">
                                <span>Видеоинструкция <i class="icon icon-video"></i></span>
                            </div> -->
                            <div class="info-text">
                                <p>Режим предназначен для внесения изменений и дополнений в
                                    утвержденный/уточненный бюджет.</p>
                                <p>Режим содержит данные до уровня бюджетных программ/подпрограмм по
                                    расходной части и специфик по поступлениям.</p>
                                <p>Данный режим состоит из разделов:</p>
                                <p><span class="red bold">Расходы</span><br> - уточнение расходной
                                    части бюджета</p>
                                <p><span class="red bold">Доходы</span><br> - уточнение доходной
                                    части бюджета</p>
                            </div>
                        </b-dropdown-text>
                    </b-dropdown>

                </div>
                <template>
                    <income-clarify :year="year"
                                    :obl="obl"
                                    :reg="region"
                                    :variant="variant"
                                    :list-reg="listReg"
                                    :list-variants="listVariants"
                                    @change_filter="changeChildFilter"
                    ></income-clarify>
                </template>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import CostClarifySpf from '@/modules/budget/budget-correct/CostClarifySpf.vue';
import IncomeClarify from '@/modules/budget/budget-correct/IncomeClarify.vue';
import {Ax} from '@/utils';
import {getActualVariant, loadVariantsByRegionCode} from '@/modules/budget/budgetVariantService';
import {CUR_YEAR} from '@/modules/budget-request/components/js/budgetCurYear';

export default {
    // name: 'BudgetCorrect',
    components: {IncomeClarify, CostClarifySpf},
    data() {
        return {
            year: CUR_YEAR + 1,
            obl: null,
            region: null,
            variant: null,
            active_tab: 0,
            selectedABP: {id: 0, child: []},
            selectedPRG: {id: 0, child: []},
            selectedPPR: {id: 0},
            listReg: [],
            listVariants: [],
            progress: 0
        };
    },

    async mounted() {
        this.year = CUR_YEAR + 1;
        await this.getObl();
        await this.loadRegions();
        const queryString = new URL(document.location.href.replace("#", "")).search;
        const params = new URLSearchParams(queryString);
        const tab = params.get("tab");
        if (tab === "1") this.active_tab = 0;
        if (tab === "2") this.active_tab = 1;
    },

    methods: {
        async changeChildFilter(filter) {
            this.variant = filter.variant;
            this.year = filter.year;
            this.region = filter.region;
            await this.loadVariantsList();
        },

        async getObl() {
            try {
                await fetch('/api-py/get-budget-obl/' + this.$store.state._instanceCode)
                    .then(response => response.json())
                    .then(json => {
                        this.obl = json.obl;
                        // this.region = json.region;
                    });
                await getActualVariant(this.region).then(data => {
                    this.variant = data;
                });
                await this.loadVariantsList();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
            }
        },

        is_available(module) {
            return this.$store.getters.user.userModules.find(value => value.modules === module)
        },

        async loadVariantsList() {
            try {
                await loadVariantsByRegionCode(this.region).then(data => {
                    this.listVariants = data;
                });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadVariantsList', error.toString());
            }
        },

        async loadRegions() { // справочник регионов
            try {
                const response = await fetch('/api-py/get-user-regions-by-obl/' + this.obl + '/' + this.$store.getters.user_uuid);
                this.listReg = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
            }
        },
        changeParams(data) {
            this.selectedABP = data.selABP;
            this.selectedPRG = data.selPRG;
            this.selectedPPR = data.selPPR;
        },
        infoCostForm(info_id) {
            const that = this;
            Ax(
                {
                    url: '/api-py/get-info/' + info_id, //  Тело файла
                    method: 'POST',
                    data: null,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    let file = '';
                    if (info_id === 3) {
                        file = 'РП Корректировка расходной части бюджета';
                    }
                    if (info_id === 4) {
                        file = 'РП Корректировка доходной части бюджета';
                    }
                    link.setAttribute('download', file + '.pdf');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );
        },

        downloadToExcelCost() {
            const currentLocale = this.$i18n.locale;
            this.progress = 0;
            // Пример: увеличиваем прогресс в процессе загрузки
            const interval = setInterval(() => {
                if (this.progress < 90) {
                    this.progress += 10;
                } else {
                    clearInterval(interval);
                }
            }, 500);
            if (this.region === null || this.variant === "") { return; }
            const params = `year=${this.year}&region=${this.region}&variant=${this.variant}&locale=${currentLocale}`;
            const urls = '/api/excel/clarification-cost-report?' + encodeURI(params);
            Ax(
                {
                    url: urls,
                    method: 'GET',
                    // data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    const docName = currentLocale === "ru"
                        ? "Отчет_по_уточнению_расходы.xlsx"
                        : "Нақтылау_жөніндегі_есеп_шығындар.xlsx";
                    link.setAttribute('download', docName);
                    document.body.appendChild(link);
                    link.click();
                    this.progress = 100;
                },
                (error) => {
                    this.error = error;
                    this.progress = 0;
                }
            );
        },

        downloadToExcelIncome() {
            const currentLocale = this.$i18n.locale;
            this.progress = 0;
            // Пример: увеличиваем прогресс в процессе загрузки
            const interval = setInterval(() => {
                if (this.progress < 90) {
                    this.progress += 10;
                } else {
                    clearInterval(interval);
                }
            }, 500);
            if (this.region === null || this.variant === "") { return; }
            const params = `year=${this.year}&region=${this.region}&variant=${this.variant}&locale=${currentLocale}`;
            const urls = '/api/excel/clarification-income-report?' + encodeURI(params);
            Ax(
                {
                    url: urls,
                    method: 'GET',
                    // data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    const docName = currentLocale === "ru"
                        ? "Отчет_по_уточнению_доходы.xlsx"
                        : "Нақтылау_жөніндегі_есеп_кірістер.xlsx";
                    link.setAttribute('download', docName);
                    document.body.appendChild(link);
                    link.click();
                    this.progress = 100;
                },
                (error) => {
                    this.error = error;
                    this.progress = 0;
                }
            );
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        } // сообщение
    }
};
</script>
<style>
/*.is-hidden {*/
/*    display: none !important;*/
/*}*/
</style>