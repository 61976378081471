// eslint-disable no-unused-vars
import {Vue} from "vue-property-decorator";
export function noAbc(evt: any) {
    // const regex = new RegExp('^-?[0-9]+$');\.?
    // const regex = new RegExp('^-?\\d*\\d{0,9}$');
    // const regex = new RegExp('^-?\\d*\\.?$');
    const regex = /^-?(0|[1-9]+)*([.,0-9])$/;
    // const key = String.fromCharCode(!evt.key ? evt.which : evt.key);
    const key = evt.key
    if (!regex.test(key)) {
        evt.preventDefault();
        return false;
    }
}// вводит только цифры
export interface BudgetCostData {
    abp: number;
    prg: number;
    ppr?: number;
    spf: number;
    cost_data_id?: number;
    abp_request?: any;
    approved_budget?: any;
    budget?: any;
    count?: any;
    bip_code: string;
    year: number;
}

export interface BudgetIncomeData {
    kat: number;
    cls: number;
    pcl: number;
    spf: number;
    prev_variant_income_data: number;
    variant_income_data: number;
}

export interface BudgetCorrectRow {
    id: number;
    region: number;
    gr: number;
    pgr: number;
    abp: number;
    prg: number;
    ppr?: number;
    spf: number;
    bip_code: string;
    field: string;
    value: number;
    year: number;
    variant: string;
    user_name: string;
    is_correct_counted: boolean;
    note: string;
}

export interface BudgetCorrectRowNew {
    variant: string
    abp: number
    prg: number
    ppr?: number
    spf: number
    bip_code?: string
    note: string
    abp_request?: number
    variant_cost_data: number
    prev_variant_cost_data?: number
    year: number
    cur_year: number
    field_values: any[]
    cost_data_id?: number
}

export interface BudgetIncomeCorrectRow {
    id: number;
    region: number;
    kat: number;
    cls: number;
    pcl: number;
    spf: number;
    field: string;
    value: number;
    year: number;
    variant: string;
    user_name: string;
    is_correct_counted: boolean;
    note: string;
}

export interface BudgetAdjust {
    id: number;
    code: string;
    name_ru: string;
    name_kk: string;
    is_income: boolean;
    beg_date?: any;
    end_date?: any;
}

export enum FilterEventOwner {
    year = 0,
    region = 1,
    variant = 2
}

export enum EbkDataType {
    abp = 3,
    prg = 4,
    ppr = 5,
    spf = 6,
    bip_code = 7,
}

export enum IncomeEbkDataType {
    kat = 1,
    cls = 2,
    pcl = 3,
    spf = 4,
}

export interface EbkName {
    data_type: EbkDataType;
    name_ru: string,
    name_kk: string
}

export interface IncomeEbkName {
    data_type: IncomeEbkDataType;
    name_ru: string,
    name_kk: string
}

export function budget_level_of_region_code(region_code: string): number {
    if (region_code==null) return 2;
    if (region_code.endsWith('0101')) return 2;
    if (region_code.endsWith('01')) return 3;
    if (!region_code.endsWith('01')) return 4;
    return 0;
}

export class IncomeCorrectRowClass {
    kat: number = NaN;
    cls: number = NaN;
    pcl: number = NaN;
    spf: number = NaN;
    field_values = new Map();
    old_field_values = new Map();
    prev_variant_income_data: number = 0;
    variant_income_data: number = 0;
    old_variant_income_data: number = 0;
    sum_correct: number = 0;
    note = "";
    old_note = "";
    year: number = NaN;
    names: IncomeEbkName[] | null = null;

    // bip_data: CostDataBipRow[] = []
    get full_code() {
        return this.kat + '0' + this.cls + this.pcl + '0' + this.spf
    };

    get full_name() {
        const ebk = this.get_name_by_data_type(IncomeEbkDataType.spf);
        if (ebk) {
            return ebk.name_ru;
        } else {
            return "Нет кода";
        }
    }

    get code_key() {
        return this.padLeadingZeros(this.kat) + this.padLeadingZeros(this.cls) +
            this.padLeadingZeros(this.pcl) + this.padLeadingZeros(this.spf)
    }

    padLeadingZeros(num ?: number) {
        const size = 3;
        let s = String(num);
        while (s.length < size) {
            s = '0' + s;
        }
        return s;
    } // добавляет 0-ли перед num до size-значного размера


    get_name_by_data_type(dt: IncomeEbkDataType) {
        return this.names?.find(value => (value.data_type === dt))
    }

    get is_correct_zero_and_may_deleted() {
        let is_zero_all_values = true;
        for (const [_, value] of this.field_values) {
            if (value !== 0) {
                is_zero_all_values = false;
                break;
            }
        }
        return is_zero_all_values || (this.variant_income_data === 0 && this.sum_correct === 0)
    }

    public set_old_values_to_new_values() {
        this.old_field_values = new Map<any, any>();
        for (const [key, value] of this.field_values) {
            this.old_field_values.set(key, value)
        }
        this.old_note = this.note;
    }

    get is_correct_changed() {
        let is_diff_old_values = false;
        for (const [key, value] of this.field_values) {
            if (this.old_field_values.get(key) !== parseFloat(value)) {
                is_diff_old_values = true;
                break
            }
        }
        return is_diff_old_values || this.old_note !== this.note;
    }

    public get_field_values() {
        if (this) {
            const res = []
            for (const [key, value] of this.field_values) {
                const v: CorrectFieldValues = {
                    key: key,
                    value: value
                }
                res.push(v)
            }
            return res;
        } else {
            return undefined;
        }
    }

    set_item_value_by_key(key: string, value: any) {
        if (typeof value == 'string') {
            if (value.length > 0) {
                value = value.replaceAll(",", ".");
                const vl = parseFloat(value.replace(/\s/g, ''));
                if (vl!=NaN) {
                    this.field_values.set(key, parseFloat(parseFloat(value.replace(/\s/g, '')).toFixed(1)));
                } else {
                    this.field_values.set(key, 0);
                }
            } else {
                this.field_values.set(key, 0);
            }
        } else if (typeof value === 'number') {
            this.field_values.set(key, parseFloat(value.toFixed(1)));
            // this._is_corrected = true;
        }
        if ((this.field_values.get(key) === 0) && (!this.old_field_values.has(key))) {
            this.field_values.delete(key);
        }
        this.sum_correct_calc();
    }

    public get_item_value_by_key(key: string) {
        for (const [fkey, value] of this.field_values) {
            if (fkey == key) {
                return value
            }
        }
        return null
    }

    public set_note_value_by_key(value: string) {
        this.note = value;
    }

    public do_correct_row(){
            this.old_variant_income_data = this.variant_income_data;
            this.variant_income_data = this.variant_income_data + this.delta;
    }


    public undo_correct_row() {
        this.variant_income_data = this.old_variant_income_data;
    }

    public sum_correct_calc() {
        let res = 0
        for (const [_, value] of this.field_values) {
            res = res + parseFloat(value)
        }
        this.sum_correct = res;
    }

    get delta(): number {
        return this.prev_variant_income_data + this.sum_correct - this.variant_income_data;
    }

    get is_updated(): boolean {
        return this.variant_income_data !== this.old_variant_income_data
    }
}

export class IncomeClarifyRowClass extends IncomeCorrectRowClass {
    public sum_correct_calc() {
        let res = 0
        for (const [code, value] of this.field_values) {
            if (code == '26') {
                res = res + parseFloat(value)
            } else {
                this.sum_correct = 0;
            }
        }
        this.sum_correct = res;
    }
}

export class CorrectRowClass {
    abp: number = NaN;
    prg: number = NaN;
    ppr?: number = undefined;
    spf: number = NaN;
    private _bip_code: string = "";
    field_values = new Map();
    old_field_values = new Map();
    prev_variant_cost_data: number = 0;
    abp_request: number = 0;
    variant_cost_data: number = 0;
    old_variant_cost_data: number = 0;
    sum_correct: number = 0;
    is_source_data: boolean = true;
    note = "";
    old_note = "";
    show_bip = false;
    ebk_duplicates: CorrectRowClass[] = [];
    names: EbkName[] | null = null;
    visible: boolean = true;
    collapsed: boolean = false;
    cost_data_id?: number = undefined;
    year: number = 0;

    get bip_code(): string {
        return this._bip_code;
    }

    get bip_code_as_null(): string | null {
        if (this._bip_code.length === 0) {
            return null
        } else {
            return this._bip_code;
        }
    }

    set bip_code(value: string) {
        if (value === null) {
            this._bip_code = ""
        } else {
            this._bip_code = value;
        }
    }

    get to_string() {
        return this.abp.toString() + ":" + this.prg.toString() + ":" + this.ppr?.toString() + ":" +
            this.spf.toString() + ":" + this._bip_code
    }

    get spf_agr_row() {
        const res: CorrectRowClass = new CorrectRowClass();
        res.abp = this.abp;
        res.prg = this.prg;
        res.ppr = this.ppr;
        res.spf = this.spf;
        res.year = this.year
        res._bip_code = "";
        res.field_values = this.field_values;
        res.is_source_data = false;
        return res;
    }

    get ppr_agr_row() {
        const res: CorrectRowClass = new CorrectRowClass();
        res.abp = this.abp;
        res.prg = this.prg;
        res.ppr = this.ppr;
        res.spf = 0;
        res.year = this.year
        res._bip_code = ""
        res.field_values = this.field_values;
        res.is_source_data = false;
        return res;
    }

    get prg_agr_row() {
        const res: CorrectRowClass = new CorrectRowClass();
        res.abp = this.abp;
        res.prg = this.prg;
        res.ppr = 0;
        res.spf = 0;
        res.year = this.year
        res._bip_code = ""
        res.field_values = this.field_values;
        res.is_source_data = false;
        return res;
    }

    get abp_agr_row() {
        const res: CorrectRowClass = new CorrectRowClass();
        res.abp = this.abp;
        res.prg = 0;
        res.ppr = 0;
        res.spf = 0;
        res.year = this.year
        res._bip_code = ""
        res.field_values = this.field_values;
        res.is_source_data = false;
        return res;
    }

    get code_key() {
        return this.padLeadingZeros(this.abp) + this.padLeadingZeros(this.prg) +
            this.padLeadingZeros(this.ppr) + this.padLeadingZeros(this.spf) + this._bip_code
    }

    padLeadingZeros(num?: number) {
        const size = 3;
        let s = String(num);
        while (s.length < size) {
            s = '0' + s;
        }
        return s;
    } // добавляет 0-ли перед num до size-значного размера

    get_name_by_data_type(dt: EbkDataType) {
        return this.names?.find(value => (value.data_type === dt))
    }

    get is_correct_zero_and_may_deleted() {
        let is_zero_all_values = true;
        for (const [_, value] of this.field_values) {
            if (value !== 0) {
                is_zero_all_values = false;
                break;
            }
        }
        return is_zero_all_values || (this.variant_cost_data === 0 && this.sum_correct === 0)
    }

    get is_sum_equal_abp() {
        return this.variant_cost_data === this.abp_request
    }

    public set_old_values_from_new_values() {
        this.old_field_values = new Map<any, any>();
        for (const [key, value] of this.field_values) {
            this.old_field_values.set(key, value)
        }
        this.old_note = this.note;
        this.old_variant_cost_data = this.variant_cost_data;
    }

    get is_correct_changed() {
        let is_diff_old_values = false;
        for (const [key, value] of this.field_values) {
            if (parseFloat(this.old_field_values.get(key)) !== parseFloat(value)) {
                is_diff_old_values = true;
                break
            }
        }
        return is_diff_old_values || this.old_note !== this.note;
    }

    public get_field_values() {
        if (this) {
            const res = []
            for (const [key, value] of this.field_values) {
                const v: CorrectFieldValues = {
                    key: key,
                    value: value
                }
                res.push(v)
            }
            return res;
        } else {
            return undefined;
        }
    }

    set_item_value_by_key(key: string, value: any) {
        if (typeof value == 'string') {
            if (value.length > 0) {
                value = value.replaceAll(",", ".");
                const vl = parseFloat(value.replace(/\s/g, ''));
                if (vl!=NaN) {
                    this.field_values.set(key, parseFloat(parseFloat(value.replace(/\s/g, '')).toFixed(1)));
                } else {
                    this.field_values.set(key, 0);
                }
            } else {
                this.field_values.set(key, 0);
            }
        } else if (typeof value === 'number') {
            this.field_values.set(key, parseFloat(value.toFixed(1)));
            // this._is_corrected = true;
        }
        if ((this.field_values.get(key) === 0) && (!this.old_field_values.has(key))) {
            this.field_values.delete(key);
        }
        this.sum_correct_calc();
    }

    public get_item_value_by_key(key: string) {
        if (this.field_values) {
            for (const [fkey, value] of this.field_values) {
                if (fkey == key) {
                    return value
                }
            }
        }
        return null
    }

    public set_note_value_by_key(value: string) {
        this.note = value;
    }

    public do_correct_row(): string {
        if (this.variant_cost_data + this.delta >= 0) {
            this.old_variant_cost_data = this.variant_cost_data;
            this.variant_cost_data = this.variant_cost_data + this.delta;
            return ""
        } else {
            return "Скорректированный бюджет не может быть отрицательным числом. Проверьте данные"
        }
    }

    public undo_correct_row() {
        this.variant_cost_data = this.old_variant_cost_data;
    }

    public sum_correct_calc() {
        let res = 0
        for (const [_, value] of this.field_values) {
            res = res + parseFloat(value)
        }
        this.sum_correct = res;
    }

    get delta(): number {
        return this.prev_variant_cost_data + this.sum_correct - this.variant_cost_data;
    }

    get is_updated(): boolean {
        return this.variant_cost_data !== this.old_variant_cost_data
    }
}

export class CorrectData {
    rows: CorrectRowClass [] = [];
    tree_rows: CorrectRowClass [] = [];
    ebk_duplicate_rows: CorrectRowClass [] = [];
    load_fields: any = []
    is_tree_mode = false;
    year: number = 0;

    public clear_rows() {
        this.tree_rows = []
        this.rows = [];
    }

    get rows_data() {
        if (!this.is_tree_mode) {
            if (this.year)
                return this.rows.filter(value => value.year === this.year)
            else
                return this.rows
        } else {
            if (this.year) {
                return this.tree_rows.filter(value => value.visible && value.year === this.year);
            } else
                return this.tree_rows.filter(value => value.visible);
        }
    }

    // get rowsData() {
    //     if (!this.is_tree_mode) {
    //         return this.year ? this.rows.filter(value => value.year === this.year) : this.rows;
    //     } else {
    //         return this.year ? this.tree_rows.filter(value => value.visible && value.year === this.year) : this.tree_rows.filter(value => value.visible);
    //     }
    // }

    get fields() {
        const result: any = [];
        result.push({
            key: "nom",
            label: "№"
        })
        result.push({
            key: "abp",
            label: "АБП",
            variant: "warning"
        })
        result.push({
            key: "prg",
            label: "ПРГ",
            variant: "warning"
        })
        result.push({
            key: "ppr",
            label: "ППР",
            variant: "warning"
        })
        result.push({
            key: "spf",
            label: "СПФ",
            variant: "warning"
        })
        result.push({
            key: "bip_code",
            label: "БИП",
            variant: "warning"
        })
        result.push({
            key: "prev_variant_cost_data",
            label: "Расходы предыдущей версии",
            variant: "success"
        })
        result.push({
            key: "abp_request",
            label: "Предложение АБП",
            variant: "success"
        })
        result.push({
            key: "variant_cost_data",
            label: "Скорректированный бюджет",
            variant: "primary"
        })
        result.push({
            key: "action",
            label: "...",
        })
        result.push({
            key: "delta",
            label: "Разница",
            variant: "danger"
        })
        result.push({
            key: "sum_correct",
            label: "Всего корректировок",
            variant: "info"
        })
        for (const f of this.load_fields) {
            result.push({
                key: f.code,
                label: f.name_ru
            })
        }
        result.push({
            key: "note",
            label: "Примечание"
        })
        return result;
    }

    public get_source_rows_sum(key: string) {
        return this.get_rows_values_sum(this.rows.filter(value => value.is_source_data && value.year === this.year), key);
    }

    private get_rows_values_sum(rows: CorrectRowClass[], key: string) {
        let res = 0;
        for (const findRow of rows) {
            res = res + findRow.get_item_value_by_key(key);
        }
        return res;
    }

    public collapse(row: CorrectRowClass) {
        if (row.prg == 0 && row.ppr == 0 && row.spf == 0 && row.bip_code == "") {
            row.collapsed = !row.collapsed;
            const find_rows: CorrectRowClass[] = this.tree_rows.filter(value => (value !== row && value.abp === row.abp));
            for (const findRow of find_rows) {
                findRow.visible = !row.collapsed;
            }
        }
        if (row.prg !== 0 && row.ppr == 0 && row.spf == 0 && row.bip_code == "") {
            row.collapsed = !row.collapsed;
            const find_rows: CorrectRowClass[] = this.tree_rows.filter(value => (value !== row && value.abp === row.abp && value.prg === row.prg));
            for (const findRow of find_rows) {
                findRow.visible = !row.collapsed;
            }
        }
        if (row.ppr) {
            if (row.prg !== 0 && row.ppr !== 0 && row.spf == 0 && row.bip_code == "") {
                row.collapsed = !row.collapsed;
                const find_rows: CorrectRowClass[] = this.tree_rows.filter(value => (value !== row && value.abp === row.abp && value.prg === row.prg && value.ppr === row.ppr));
                for (const findRow of find_rows) {
                    findRow.visible = !row.collapsed;
                }
            } else {
                if (row.prg !== 0 && row.spf == 0 && row.bip_code == "") {
                    row.collapsed = !row.collapsed;
                    const find_rows: CorrectRowClass[] = this.tree_rows.filter(value => (value !== row && value.abp === row.abp));
                    for (const findRow of find_rows) {
                        findRow.visible = !row.collapsed;
                    }
                }
            }
        }
        if (row.prg !== 0 && row.spf !== 0 && row.bip_code == "") {
            row.collapsed = !row.collapsed;
            const find_rows: CorrectRowClass[] = this.tree_rows.filter(value => (value !== row && value.abp === row.abp && value.prg === row.prg && value.ppr === row.ppr && value.spf === row.spf));
            for (const findRow of find_rows) {
                findRow.visible = !row.collapsed;
            }
        }
    }

    public get_item_value_by_key(row: CorrectRowClass, key: string) {
        if (row.prg == 0 && row.ppr == 0 && row.spf == 0 && row.bip_code == "") {
            const find_rows: CorrectRowClass[] = this.tree_rows.filter(value => (value.is_source_data && value.abp === row.abp));
            return this.get_rows_values_sum(find_rows, key);
        }
        if (row.prg !== 0 && row.ppr == 0 && row.spf == 0 && row.bip_code == "") {
            const find_rows: CorrectRowClass[] = this.tree_rows.filter(value => (value.is_source_data && value.abp === row.abp && value.prg === row.prg));
            return this.get_rows_values_sum(find_rows, key);
        }
        if (row.ppr) {
            if (row.prg !== 0 && row.ppr !== 0 && row.spf == 0 && row.bip_code == "") {
                const find_rows: CorrectRowClass[] = this.tree_rows.filter(value => (value.is_source_data && value.abp === row.abp && value.prg === row.prg && value.ppr === row.ppr));
                return this.get_rows_values_sum(find_rows, key);
            }
        } else {
            if (row.prg !== 0 && row.spf == 0 && row.bip_code == "") {
                const find_rows: CorrectRowClass[] = this.tree_rows.filter(value => (value.is_source_data && value.abp === row.abp && value.prg === row.prg));
                return this.get_rows_values_sum(find_rows, key);
            }
        }
        if (row.prg !== 0 && row.spf !== 0 && row.bip_code == "") {
            const find_rows: CorrectRowClass[] = this.tree_rows.filter(value => (value.is_source_data &&
                value.abp === row.abp && value.prg === row.prg && value.ppr === row.ppr && value.spf == row.spf));
            return this.get_rows_values_sum(find_rows, key);
        }
        if (row.field_values) {
            for (const [fkey, value] of row.field_values) {
                if (fkey == key) {
                    return value
                }
            }
        }
        return null
    }

    public get_data_sum(row: CorrectRowClass, field_name: string) {
        if (row.is_source_data) {
            return (row as any)[field_name];
        } else {
            if (row.prg == 0 && row.ppr == 0 && row.spf == 0 && row.bip_code == "") {
                const find_rows: any[] = this.tree_rows.filter(value => (value.is_source_data && value.abp === row.abp));
                let res = 0;
                for (const findRow of find_rows) {
                    res = res + findRow[field_name];
                }
                return res;
            }
            if (row.prg !== 0 && row.ppr == 0 && row.spf == 0 && row.bip_code == "") {
                const find_rows: any[] = this.tree_rows.filter(value => (value.is_source_data && value.abp === row.abp && value.prg === row.prg));
                let res = 0;
                for (const findRow of find_rows) {
                    res = res + findRow[field_name];
                }
                return res;
            }
            if (row.ppr) {
                if (row.prg !== 0 && row.ppr !== 0 && row.spf == 0 && row.bip_code == "") {
                    const find_rows: any[] = this.tree_rows.filter(value => (value.is_source_data && value.abp === row.abp && value.prg === row.prg && value.ppr === row.ppr));
                    let res = 0;
                    for (const findRow of find_rows) {
                        res = res + findRow[field_name];
                    }
                    return res;
                }
            } else {
                if (row.prg !== 0 && row.spf == 0 && row.bip_code == "") {
                    const find_rows: any[] = this.tree_rows.filter(value => (value.is_source_data && value.abp === row.abp && value.prg === row.prg));
                    let res = 0;
                    for (const findRow of find_rows) {
                        res = res + findRow[field_name];
                    }
                    return res;
                }
            }
            if (row.prg !== 0 && row.spf !== 0 && row.bip_code == "") {
                const find_rows: any[] = this.tree_rows.filter(value => (value.is_source_data &&
                    value.abp === row.abp && value.prg === row.prg && value.ppr === row.ppr && value.spf == row.spf));
                let res = 0;
                for (const findRow of find_rows) {
                    res = res + findRow[field_name];
                }
                return res;
            }
        }
    }

    public add_ebk_code(ebk: any): CorrectRowClass | undefined {
        const table_row = new CorrectRowClass();
        table_row.abp = ebk.abp.abp;
        table_row.prg = ebk.prog.prg;
        table_row.year = this.year;
        if (ebk.subProg) {
            table_row.ppr = ebk.subProg.ppr;
        }
        table_row.spf = ebk.spf.spf;
        if (ebk.bip !== null) {
            table_row.bip_code = ebk.bip.bip_code;
        }
        if (ebk.prog.develop_type === 1 && ebk.bip === null) {
            return undefined;
        }
        return table_row
    }

    public delete_row(row: CorrectRowClass) {
        const ind = this.rows.indexOf(row);
        this.rows.splice(ind, 1);
    }

    public async add_correct_data(row: BudgetCorrectRowNew) {
        const table_row = new CorrectRowClass()
        table_row.abp = row.abp;
        table_row.prg = row.prg;
        table_row.ppr = row.ppr;
        table_row.spf = row.spf;
        table_row.year = row.year;
        if (row.bip_code) table_row.bip_code = row.bip_code;
        table_row.note = row.note;
        table_row.old_note = row.note;
        if (row.abp_request) table_row.abp_request = row.abp_request;
        if (row.prev_variant_cost_data) table_row.prev_variant_cost_data = row.prev_variant_cost_data;
        if (row.variant_cost_data) table_row.variant_cost_data = row.variant_cost_data;
        if (row.variant_cost_data) table_row.old_variant_cost_data = row.variant_cost_data
        if (row.cost_data_id) table_row.cost_data_id = row.cost_data_id
        table_row.note = row.note;
        table_row.old_note = row.note;
        for (const fieldValue of row.field_values) {
            table_row.field_values.set(fieldValue['key'], fieldValue['value'])
        }
        table_row.set_old_values_from_new_values();
        table_row.sum_correct_calc();
        this.rows_push(table_row);
    }

    public rows_push(row: CorrectRowClass) {
        this.rows.push(row);
        this.tree_rows_push(row);
    }

    private tree_rows_push(row: CorrectRowClass) {
        // if (!this.find_in_by_code_in_tree_rows(row)) {
        this.tree_rows.push(row);
        // }
        let new_row = row.spf_agr_row;
        if (!this.find_in_by_code_in_tree_rows(new_row)) {
            this.tree_rows.push(new_row)
        }
        new_row = row.ppr_agr_row;
        if (!this.find_in_by_code_in_tree_rows(new_row)) {
            this.tree_rows.push(new_row)
        }
        new_row = row.prg_agr_row;
        if (!this.find_in_by_code_in_tree_rows(new_row)) {
            this.tree_rows.push(new_row)
        }
        new_row = row.abp_agr_row;
        if (!this.find_in_by_code_in_tree_rows(new_row)) {
            this.tree_rows.push(new_row)
        }
    }

    private find_in_by_code_in_tree_rows(row: CorrectRowClass) {
        return this.find_by_code_in_rows(this.tree_rows, row.abp, row.prg, row.bip_code, row.spf, row.year, row.ppr);
    }

    public find_by_code(abp: number, prg: number, bip: string, spf: number, year: number, ppr?: number): CorrectRowClass | undefined {
        return this.find_by_code_in_rows(this.rows, abp, prg, bip, spf, year, ppr);
    }

    private find_by_code_in_rows(rows: CorrectRowClass[], abp: number, prg: number, bip_code: string, spf: number, year: number, ppr?: number): CorrectRowClass | undefined {
        if (ppr !== undefined) {
            if ((bip_code !== null)) {
                return rows.find(value => (value.abp === abp && value.prg === prg && value.ppr === ppr && value.spf === spf && value.year === year && value.bip_code === bip_code));
            } else {
                return rows.find(value => (value.abp === abp && value.prg === prg && value.ppr === ppr && value.spf === spf && value.year === year));
            }
        } else {
            if (bip_code !== null) {
                return rows.find(value => (value.abp === abp && value.prg === prg && value.spf === spf && value.year === year && value.bip_code === bip_code))
            } else {
                return rows.find(value => (value.abp === abp && value.prg === prg && value.spf === spf && value.year === year))
            }
        }
    }


    public find_row_ebk_duplicates() {
        for (const row of this.rows) {
            row.ebk_duplicates = [];
            for (const row1 of this.rows) {
                if (row !== row1) {
                    if (row.abp === row1.abp && row.prg === row1.prg && row.ppr === row1.ppr && row.spf === row1.spf && row.bip_code === row1.bip_code) {
                        row.ebk_duplicates.push(row1);
                    }
                }
            }
        }
    }

    public async sort_row_array_by_code(rows: CorrectRowClass[]) {
        rows.sort((a, b) => {
            return a.code_key >= b.code_key ? 1 : -1;
        })
    }

    public async sort_data_by_code() {
        await this.sort_row_array_by_code(this.tree_rows);
        await this.sort_row_array_by_code(this.rows);
        this.find_row_ebk_duplicates();
    }

    public is_key_field_visible(row: CorrectRowClass, field: string) {
        const fields = ['abp', 'prg', 'ppr', 'spf', 'bip_code'];
        const row_index = this.rows.indexOf(row);
        if (row_index == 0) {
            return true
        } else {
            const prev_row = this.rows[row_index - 1];
            let res = true;
            for (const fld of fields) {
                if (prev_row) {
                    const p_val = prev_row[fld as keyof typeof prev_row];
                    const val = row[fld as keyof typeof row];
                    if (p_val === val) {
                        res = false;
                    } else {
                        return true;
                    }
                    if (fld === field) {
                        return res;
                    }
                }
            }
        }
    }

    public is_key_field_visible_in_tree(row: CorrectRowClass, field: string) {
        const fields = ['abp', 'prg', 'ppr', 'spf', 'bip_code'];
        const row_index = this.tree_rows.indexOf(row);
        if (row_index == 0) {
            return true
        } else {
            const prev_row = this.tree_rows[row_index - 1];
            let res = true;
            for (const fld of fields) {
                if (prev_row) {
                    const p_val = prev_row[fld as keyof typeof prev_row];
                    const val = row[fld as keyof typeof row];
                    if (p_val === val) {
                        res = false;
                    } else {
                        return true;
                    }
                    if (fld === field) {
                        return res;
                    }
                }
            }
        }
    }
}

export class ClarifyData extends CorrectData {
    get fields() {
        const result: any = [];
        result.push({
            key: "nom",
            label: "№"
        })
        result.push({
            key: "abp",
            label: "АБП",
            variant: "warning"
        })
        result.push({
            key: "prg",
            label: "ПРГ",
            variant: "warning"
        })
        result.push({
            key: "ppr",
            label: "ППР",
            variant: "warning"
        })
        result.push({
            key: "spf",
            label: "СПФ",
            variant: "warning"
        })
        result.push({
            key: "bip_code",
            label: "БИП",
            variant: "warning"
        })
        result.push({
            key: "prev_variant_cost_data",
            label: "Расходы предыдущей версии",
            variant: "success"
        })
        result.push({
            key: "abp_request",
            label: "Предложение АБП",
            variant: "success"
        })
        result.push({
            key: "variant_cost_data",
            label: "Уточненный бюджет",
            variant: "primary"
        })
        result.push({
            key: "action",
            label: "...",
        })
        result.push({
            key: "delta",
            label: "Разница",
            variant: "danger"
        })
        result.push({
            key: "sum_correct",
            label: "Всего уточнений",
            variant: "info"
        })
        for (const f of this.load_fields) {
            result.push({
                key: f.code,
                label: f.name_ru
            })
        }
        result.push({
            key: "note",
            label: "Примечание"
        })
        return result;
    }

}

export class IncomeCorrectData {
    rows: IncomeCorrectRowClass [] = [];
    load_fields: BudgetAdjust[] = []
    table_mode = false;

    get fields() {
        const result: any = [];
        result.push({
            key: "nom",
            label: "№"
        })
        if (this.table_mode) {
            result.push({
                key: "full_code",
                label: "Полный код",
                variant: "warning"
            })
            result.push({
                key: "full_name",
                label: "Наименование",
                variant: "warning"
            })
        } else {
            result.push({
                key: "kat",
                label: "Кат",
                variant: "warning"
            })
            result.push({
                key: "cls",
                label: "Клс",
                variant: "warning"
            })
            result.push({
                key: "pcl",
                label: "Пдк",
                variant: "warning"
            })
            result.push({
                key: "spf",
                label: "СПФ",
                variant: "warning"
            })
        }
        result.push({
            key: "prev_variant_income_data",
            label: "Поступления предыдущей версии",
            variant: "success"
        })
        result.push({
            key: "variant_income_data",
            label: "Скорректированные поступления выбранной версии",
            variant: "primary"
        })
        result.push({
            key: "action",
            label: "...",
        })
        result.push({
            key: "delta",
            label: "Разница",
            variant: "danger"
        })
        result.push({
            key: "sum_correct",
            label: "Всего корректировок",
            variant: "info"
        })
        for (const f of this.load_fields.filter(v => (v.is_income))) {
            result.push({
                key: f.code,
                label: f.name_ru
            })
        }
        result.push({
            key: "note",
            label: "Примечание"
        })
        return result;
    }

    public get_source_rows_sum(key: string) {
        return this.get_rows_values_sum(this.rows, key);
    }

    private get_rows_values_sum(rows: IncomeCorrectRowClass[], key: string) {
        let res = 0;
        for (const findRow of rows) {
            res = res + findRow.get_item_value_by_key(key);
        }
        return res;
    }

    public add_ebk_code(ebk: any): IncomeCorrectRowClass {
        const table_row = new IncomeCorrectRowClass();
        table_row.kat = ebk.curKat.kat;
        table_row.cls = ebk.curCls.cls;
        table_row.pcl = ebk.curPcl.pcl;
        table_row.spf = ebk.curSpf.spf;
        return table_row
    }

    public delete_row(row: IncomeCorrectRowClass) {
        const ind = this.rows.indexOf(row);
        this.rows.splice(ind, 1);
    }

    public async add_correct_data(row: BudgetIncomeCorrectRow) {
        const field_val = this.load_fields.find(value => (value.code === row.field && value.is_income));
        if (field_val === undefined) {
            return;
        }
        let table_row: IncomeCorrectRowClass | undefined = this.find_by_code(row.kat, row.cls, row.pcl, row.spf)
        if (table_row) {
            table_row.field_values.set(row.field, row.value);
        } else {
            table_row = new IncomeCorrectRowClass();
            table_row.kat = row.kat;
            table_row.cls = row.cls;
            table_row.pcl = row.pcl;
            table_row.spf = row.spf;
            table_row.note = row.note;
            table_row.old_note = row.note;
            table_row.field_values.set(row.field, row.value);
            this.rows.push(table_row);
        }
        table_row.set_old_values_to_new_values();
        table_row.sum_correct_calc();
    }

    public find_by_code(kat: number, cls: number, pcl: number, spf: number): IncomeCorrectRowClass | undefined {
        return this.rows.find(value => (value.kat == kat && value.cls == cls && value.pcl == pcl && value.spf == spf));
    }

    public async sort_data_by_code() {
        this.rows.sort((a, b) => {
            return a.code_key >= b.code_key ? 1 : -1;
        })
    }

    public is_key_field_visible(row: IncomeCorrectRowClass, field: string) {
        const fields = ['kat', 'cls', 'pcl', 'spf'];
        const row_index = this.rows.indexOf(row);
        if (row_index == 0) {
            return true
        } else {
            const prev_row = this.rows[row_index - 1];
            let res = true;
            for (const fld of fields) {
                const p_val = prev_row[fld as keyof typeof prev_row];
                const val = row[fld as keyof typeof row];
                if (p_val === val) {
                    res = false;
                } else {
                    return true;
                }
                if (fld === field) {
                    return res;
                }
            }
        }
    }
}

export class IncomeClarifyData {
    rows: IncomeClarifyRowClass [] = [];
    load_fields: any[] = []
    table_mode = false;
    year: number = 2023;

    get year_rows() {
        return this.rows.filter(value => value.year == this.year);
    }

    get fields() {
        const result: any = [];
        result.push({
            key: "nom",
            label: "№"
        })
        if (this.table_mode) {
            result.push({
                key: "full_code",
                label: "Полный код",
                variant: "warning"
            })
            result.push({
                key: "full_name",
                label: "Наименование",
                variant: "warning"
            })
        } else {
            result.push({
                key: "kat",
                label: "Кат",
                variant: "warning"
            })
            result.push({
                key: "cls",
                label: "Клс",
                variant: "warning"
            })
            result.push({
                key: "pcl",
                label: "Пдк",
                variant: "warning"
            })
            result.push({
                key: "spf",
                label: "СПФ",
                variant: "warning"
            })
        }
        result.push({
            key: "prev_variant_income_data",
            label: "Поступления предыдущей версии",
            variant: "success"
        })
        result.push({
            key: "variant_income_data",
            label: "Уточненные поступления выбранной версии",
            variant: "primary"
        })
        result.push({
            key: "action",
            label: "...",
        })
        result.push({
            key: "delta",
            label: "Разница",
            variant: "danger"
        })
        result.push({
            key: "sum_correct",
            label: "Всего уточнений",
            variant: "info"
        })
        for (const f of this.load_fields.filter(v => (v.is_income))) {
            result.push({
                key: f.code,
                label: f.name_ru
            })
        }
        result.push({
            key: "note",
            label: "Примечание"
        })
        return result;
    }

    public get_source_rows_sum(key: string) {
        return this.get_rows_values_sum(this.year_rows, key);
    }

    private get_rows_values_sum(rows: IncomeClarifyRowClass[], key: string) {
        let res = 0;
        for (const findRow of rows) {
            res = res + findRow.get_item_value_by_key(key);
        }
        return res;
    }

    public add_ebk_code(ebk: any): IncomeClarifyRowClass {
        const table_row = new IncomeClarifyRowClass();
        table_row.kat = ebk.curKat.kat;
        table_row.cls = ebk.curCls.cls;
        table_row.pcl = ebk.curPcl.pcl;
        table_row.spf = ebk.curSpf.spf;
        table_row.year = this.year;
        return table_row
    }

    public delete_row(row: IncomeClarifyRowClass) {
        const ind = this.rows.indexOf(row);
        this.rows.splice(ind, 1);
    }

    public async add_correct_data(row: BudgetIncomeCorrectRow) {
        const field_val = this.load_fields.find(value => (value.code === row.field && value.is_income));
        if (field_val === undefined) {
            return;
        }
        let table_row: IncomeClarifyRowClass | undefined = this.find_by_code(row.kat, row.cls, row.pcl, row.spf, row.year)
        if (table_row) {
            table_row.field_values.set(row.field, row.value);
        } else {
            table_row = new IncomeClarifyRowClass();
            table_row.kat = row.kat;
            table_row.cls = row.cls;
            table_row.pcl = row.pcl;
            table_row.spf = row.spf;
            table_row.year = row.year;
            table_row.note = row.note;
            table_row.old_note = row.note;
            table_row.field_values.set(row.field, row.value);
            this.rows.push(table_row);
        }
        table_row.set_old_values_to_new_values();
        table_row.sum_correct_calc();
    }

    public find_by_code(kat: number, cls: number, pcl: number, spf: number, year: number): IncomeClarifyRowClass | undefined {
        return this.rows.find(value => (value.kat == kat && value.cls == cls && value.pcl == pcl && value.spf == spf && value.year == year));
    }

    public async sort_data_by_code() {
        this.rows.sort((a, b) => {
            return a.code_key >= b.code_key ? 1 : -1;
        })
    }

    public is_key_field_visible(row: IncomeClarifyRowClass, field: string) {
        const fields = ['kat', 'cls', 'pcl', 'spf'];
        const row_index = this.rows.indexOf(row);
        if (row_index == 0) {
            return true
        } else {
            const prev_row = this.rows[row_index - 1];
            let res = true;
            for (const fld of fields) {
                const p_val = prev_row[fld as keyof typeof prev_row];
                const val = row[fld as keyof typeof row];
                if (p_val === val) {
                    res = false;
                } else {
                    return true;
                }
                if (fld === field) {
                    return res;
                }
            }
        }
    }
}


export interface CorrectFieldValues {
    key: string;
    value: number;
}

export interface CorrectSaveData {
    variant: string;
    abp: number;
    prg: number;
    ppr?: number;
    spf: number;
    bip_code: string | null;
    note: string;
    variant_cost_data: number;
    sum_correct: number;
    field_values: any;
    user_name: string;
    year: number;
    cur_year: number;
    is_correct_changed: boolean;
    is_cost_changed: boolean;
}

export interface IncomeCorrectSaveData {
    variant: string;
    kat: number;
    cls: number;
    pcl: number;
    spf: number;
    note: string;
    variant_income_data: number;
    sum_correct: number;
    field_values: any;
    user_name: string;
    curYear: number;
    year: number;
    is_correct_changed: boolean;
    is_income_changed: boolean;
}

export interface EbkCodeRequest {
    abp: number;
    prg?: number;
    ppr?: number;
    spf?: number;
    bip_code?: string;
}

export interface EbkIncomeCodeRequest {
    kat: number;
    cls?: number;
    pcl?: number;
    spf?: number;
}
